import merchantInvoiceList from "./list"
import invoiceDetail from "./detail"

const merchantInvoice = {
    path: '/merchantInvoice',
    component: resolve => require(['../../../../pages/admin/merchantInvoice'], resolve),
    redirect: '/merchantInvoice/list',
    children: [
        merchantInvoiceList,
        invoiceDetail
    ],
    meta: {
        requireAuth: true
    }
}

export default merchantInvoice
